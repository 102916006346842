import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TopBar } from 'idtrusted-topbar';
import './style.css';
import NavMenuButton from '../buttons/navMenuButton';
import { setDataPage } from '../../redux/actions/page';
import { getProfile } from '../../api/users';
import {
	URL_SERVICES,
	URL_SERVICE_CRM_TOOLS,
	URL_SERVICE_TRUSTED,
	SERVICE_NAME,
	SERVICE_GROUP_NAME, VERSION_TEXT,
} from '../../utils/constants';
import Apps from '../icons/apps';
import Log from '../icons/log';
import GetterIcon from '../icons/getter';
import AutoCallIcon from '../icons/autocall';
import CrmTools from '../icons/crmtools';
import AuthorizationIcon from '../icons/authorization';
import ShopIcon from '../../images/icons/shop.svg';
import Wallet from '../icons/wallet';
import { logoutAuth } from '../../api/auth';

const Header = (props) => {
	const dispatch = useDispatch();
	const page = useSelector((state) => state.page);
	const profile = useSelector((state) => state.profile);
	const { showApp } = props;

	const changePage = (text) => {
		if (text !== page.data) {
			dispatch(setDataPage(text));
		}
	};

	const onClickMenuService = (serviceName) => {
		switch (serviceName) {
			case 'couner': {
				window.open(`${URL_SERVICES}/${serviceName}`);
				break;
			}
			case 'obzvon': {
				window.open(`${URL_SERVICES}/${serviceName}`);
				break;
			}
			case 'crmtools': {
				window.open(URL_SERVICE_CRM_TOOLS);
				break;
			}
			case 'trusted': {
				window.open(URL_SERVICE_TRUSTED);
				break;
			}
			default: break;
		}
	};

	const menuList = [
		{
			title: 'Прием показаний',
			onClick: () => onClickMenuService('counter'),
			icon: <GetterIcon/>,
			allowedRoles: 'USER'
		},
		{
			title: 'Обзвон должников',
			onClick: () => onClickMenuService('obzvon'),
			icon: <AutoCallIcon/>,
			allowedRoles: 'USER'
		},
		{
			title: 'Обработка заказов',
			onClick: () => onClickMenuService('crmtools'),
			icon: <CrmTools/>,
			allowedRoles: 'USER'
		},
		{
			title: 'Сервис аутентификации',
			onClick: () => onClickMenuService('trusted'),
			icon: <AuthorizationIcon/>,
			allowedRoles: 'USER'
		},
	];

	const exitButtonStyles = {
		backgroundColor: '#2186F0',
		hover: '#1077E5',
		borderRadius: '3px'
	};

	React.useEffect(() => {
		getProfile()
			.then(() => showApp());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<header className="header">
			<div className="header__centralized_container">
				<TopBar
					projectInfo={{
						items: menuList,
						logoUrl: ShopIcon,
						menuButtonDisabled: false,
						onExitClick: logoutAuth,
						projectName: SERVICE_GROUP_NAME,
						sectionName: SERVICE_NAME,
						version: VERSION_TEXT,
						copyright: 'COPYRIGHT',
						manualUrl: 'MANUAL_URL'
					}}
					userInfo={{
						role: 'USER',
						username: profile.data.name,
						avatarUrl: '',
						onUserClick: () => {},
					}}
					classes={{
						topBar: "header__top_bar",
					}}
					customStyles={exitButtonStyles}
				/>
			</div>
			<div className="navbar">
				<div className="navbar-header">
					<div className="navbar-header__container">
						<NavMenuButton
							text="Приложения"
							page={page.data}
							onClick={changePage}
							icon={<Apps />}
						/>
						<NavMenuButton
							text="Журнал"
							page={page.data}
							onClick={changePage}
							icon={<Log />}
						/>
						<NavMenuButton
							text="Лицензии"
							page={page.data}
							onClick={changePage}
							icon={<Wallet />}
						/>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
