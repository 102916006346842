import React from 'react';
import './style.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomSelect from '../../customSelect';
import { useSelector, useDispatch } from 'react-redux';
import CustomDatePicker from '../../customDatePicker';
import { makeStyles } from '@material-ui/core/styles';
import ArrowSort from '../../icons/arrowSort';
import {
	setFilterCalls,
	setSortCalls,
	setPageCalls,
	setSearchStartCalls,
	setSearchEndCalls,
	setSearchOptionCalls,
	setDataCalls,
} from '../../../redux/actions/calls';
import { setDataSelected } from '../../../redux/actions/apps';
import { columnsCalls } from '../../../components/tables/columns';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import CustomSearch from '../../customSearch';
import { getCalls, getMoreCalls } from '../../../api/calls';
import CircularProgress from '@mui/material/CircularProgress';
import { codeTypes, translateCodes } from '../../../utils/constants';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiTable-root': {
			display: 'flex',
			flexDirection: 'column',
			width: '1000px',
			border: '1px solid #E0E2E7',
			borderBottom: 'none',
			boxSizing: 'border-box',
			borderRadius: 3,
		},
		'& .MuiTableRow-root': {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			width: '100%',
			borderBottom: '1px solid #E0E2E7',
		},
		'& .MuiTableCell-root': {
			borderBottom: 'none',
			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '15px',
			lineHeight: '22px',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		'& .MuiTableHead-root .MuiTableCell-root': {
			color: '#8387A0',
			// padding: '16px 100px 15px 20px',
		},
		'& .MuiTableHead-root .service_phone_cell': {
			paddingRight: 55,
		},
		'& .MuiTableBody-root .MuiTableCell-root': {
			color: '#273270',
			// padding: '16px 100px 15px 23px',
		},
		'& .MuiTableBody-root .phone_cell': {
			paddingRight: 60,
		},
	},
}));

const LogTable = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const stateApp = useSelector((state) => state.apps);
	const stateCalls = useSelector((state) => state.calls);
	const [loading, setLoading] = React.useState(false);
	const [startDate, setStartDate] = React.useState(
		new Date(stateCalls.searchStart.replace(' ', 'T'))
	);
	const [endDate, setEndDate] = React.useState(
		new Date(stateCalls.searchEnd.replace(' ', 'T'))
	);

	const changeApp = (value) => {
		dispatch(setPageCalls(1));
		dispatch(setDataSelected(value));
	};

	const changeDate = (start, end) => {
		setStartDate(start);
		setEndDate(end);
		const newStartDate = `${start.getFullYear()}-${start.getMonth() + 1 < 10
				? `0${start.getMonth() + 1}`
				: start.getMonth() + 1
			}-${start.getDate() < 10 ? `0${start.getDate()}` : start.getDate()
			} 00:00:00`;
		const newEndDate = `${end.getFullYear()}-${end.getMonth() + 1 < 10 ? `0${end.getMonth() + 1}` : end.getMonth() + 1
			}-${end.getDate() < 10 ? `0${end.getDate()}` : end.getDate()} 23:59:59`;
		dispatch(setPageCalls(1));
		dispatch(setSearchStartCalls(newStartDate));
		dispatch(setSearchEndCalls(newEndDate));
		dispatch(setSearchOptionCalls('period'));
	};

	const createSortHandler = (e, field) => {
		const isAsc =
			stateCalls.sort.sortField === field &&
			stateCalls.sort.sortOrder === 'asc';
		dispatch(setPageCalls(1));
		dispatch(
			setSortCalls({
				sortOrder: isAsc ? 'desc' : 'asc',
				sortField: field,
			})
		);
	};

	const onSearch = (value) => {
		if (value.length) {
			dispatch(setPageCalls(1));
			dispatch(
				setFilterCalls({
					phone: {
						filterVal: value,
					},
				})
			);
		} else dispatch(setFilterCalls({}));
	};

	const scrollBottom = async () => {
		if (
			document.documentElement.scrollHeight -
			document.documentElement.scrollTop -
			window.innerHeight <=
			70 &&
			!loading
			// window.innerHeight * stateCalls.page - document.body.getBoundingClientRect().bottom >=
			// 	-70 &&
			// !loading
		) {
			if (
				Number(
					stateCalls.totalSize - stateCalls.page * stateCalls.sizePerPage
				) > 0
			) {
				setLoading(true);
				dispatch(setPageCalls(stateCalls.page + 1));
				await getMoreCalls();
				setLoading(false);
			}
		}
	};

	const handleServer = (name, type) => {
		return type !== codeTypes.number ? name
			: `${name.slice(
				0,
				2
			)} ${name.slice(
				2,
				5
			)} ${name.slice(
				5,
				8
			)}-${name.slice(
				8,
				10
			)}-${name.slice(10)}`
	}

	React.useEffect(() => {
		window.addEventListener('scroll', scrollBottom);
		return () => {
			window.removeEventListener('scroll', scrollBottom);
		};
	});

	React.useEffect(() => {
		dispatch(setDataCalls([]));
		dispatch(setPageCalls(1));
		(async () => {
			setLoading(true);
			await getCalls();
			setTimeout(() => setLoading(false), 500);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		stateCalls.sort,
		stateCalls.filter,
		stateApp.selected,
		stateCalls.searchStart,
		stateCalls.searchEnd,
	]);

	return (
		<div className="log_table">
			<div className="log_table__toolbar">
				<CustomSelect
					value={stateApp.selected}
					changeValue={changeApp}
					list={stateApp.data}
					valueField="client_id"
					nameField="name"
				/>
				<CustomDatePicker
					startDate={startDate}
					endDate={endDate}
					onChangeDate={changeDate}
				/>
				<CustomSearch
					value={
						stateCalls.filter.phone ? stateCalls.filter.phone.filterVal : ''
					}
					onSearch={onSearch}
				/>
			</div>
			<div
				className={
					stateCalls.data.length
						? 'log_table__table'
						: 'log_table__table hidden_block'
				}
			>
				<TableContainer className={classes.root}>
					<Table>
						<TableHead>
							<TableRow>
								{columnsCalls.map((column) => (
									<TableCell
										align="center"
										key={column.field}
										style={{
											width: column.width,
										}}
										padding="normal"
										sortDirection={
											stateCalls.sort.sortField === column.field
												? stateCalls.sort.sortOrder
												: false
										}
									>
										<TableSortLabel
											active={stateCalls.sort.sortField === column.field}
											hideSortIcon={true}
											direction={
												stateCalls.sort.sortField === column.field
													? stateCalls.sort.sortOrder
													: 'asc'
											}
											onClick={(e) => createSortHandler(e, column.field)}
											IconComponent={() => (
												<ArrowSort
													direction={
														stateCalls.sort.sortField === column.field
															? stateCalls.sort.sortOrder
															: 'asc'
													}
												/>
											)}
										>
											{column.headerName}
										</TableSortLabel>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{stateCalls.data.map((call) => (
								<TableRow key={call.id}>
									<TableCell
										align="left"
										style={{
											width: 450,
										}}
									>
										{call.createAt.slice(8, 10) +
											'.' +
											call.createAt.slice(5, 7) +
											'.' +
											call.createAt.slice(0, 4) +
											' ' +
											(new Date(new Date(call.createAt).getTime()).getHours() <
												10
												? `0${new Date(
													new Date(call.createAt).getTime()
												).getHours()}`
												: new Date(
													new Date(call.createAt).getTime()
												).getHours()) +
											':' +
											(new Date(
												new Date(call.createAt).getTime()
											).getMinutes() < 10
												? `0${new Date(
													new Date(call.createAt).getTime()
												).getMinutes()}`
												: new Date(
													new Date(call.createAt).getTime()
												).getMinutes()) +
											':' +
											(new Date(
												new Date(call.createAt).getTime()
											).getSeconds() < 10
												? `0${new Date(
													new Date(call.createAt).getTime()
												).getSeconds()}`
												: new Date(
													new Date(call.createAt).getTime()
												).getSeconds())}
									</TableCell>
									<TableCell
										align="left"
										style={{
											width: 150,
										}}
									>{translateCodes[call.code_type] || translateCodes.number}</TableCell>
									<TableCell
										align="left"
										style={{
											width: 400,
										}}
									>
										{handleServer(call.service_contact, call.code_type)}
									</TableCell>
									<TableCell
										align="left"
										style={{
											width: 350,
										}}
									>
										{`${call.phone.slice(0, 2)} ${call.phone.slice(
											2,
											5
										)} ${call.phone.slice(5, 8)}-${call.phone.slice(
											8,
											10
										)}-${call.phone.slice(10)}`}
									</TableCell>
									<TableCell
										align="left"
										style={{
											width: 220,
										}}
									>
										{call.success ? 'Успешно' : 'Не успешно'}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
			<div className={loading ? 'log_table__preloader' : 'hidden_block'}>
				<CircularProgress />
			</div>
			<div
				className={
					stateCalls.data.length || loading
						? 'hidden_block'
						: 'log_table__no_result'
				}
			>
				<h3>Результаты поиска</h3>
				<span className="log_table__no_result__span">
					По вашему запросу ничего не найдено
				</span>
			</div>
		</div>
	);
};

export default LogTable;
